import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { concat, debounce, isEmpty, isNil } from 'lodash';
import { selectIsNavBarCollapsed } from 'features/loggedInRoutesPanel/slice';
import { setIsSectionsExpanded } from 'store/srNewSlice';
import {
  resetFieldValidationStatus,
  selectForceFloatingTicketShowValidationError,
  selectIsNewSrFieldsValid,
  setForceFloatingTicketShowError,
} from 'store/fieldValidationsSlice';
import { selectFormData, updateIsDirtyStatus } from 'store/isDirtyFieldSlice';
import { ResourceType, SubResourceType } from 'services/attachments';
import { useReassignTempAttachmentsMutation, useTicketAttachments } from 'remote-state/attachments';
import { useApplicationData } from 'remote-state/applicationHooks';
import { QUERIES_KEYS } from 'constant';
import {
  useCategoryDrivenTemplate,
  useCreateNewSR,
  useLinkedSrLists,
  useSaveSR,
  useUpdateAssociatedFields,
  useAiTitleAndCategories,
} from 'remote-state/ticketServiceHooks';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { useAutoPopulationEnabled, useCategoryDrivenEnabled } from 'remote-state/accountSettingsServiceHooks';
import Header from 'features/header/header';
import SrPanel from 'features/srPanel';
import { useGetTemplateById } from 'remote-state/templateHooks';
import { mapToSrKey } from 'common/utils/srUtils';
import { castCategoryDefaultToNumber, getCategoryFieldUpdates } from 'features/templateBuilder/utils/utils';
import { getRichTextValueByColumnName } from 'common/components/controls/RichTextEditor/utils';
import { AutoPopulateTicket } from 'common/components/autoPopulateTicket';
import { useMainAssetAndCi, useUserInfo } from 'remote-state/userServiceHooks';
import { fieldValueExists, getFieldValue, getFieldsMap } from 'common/utils/fieldUtils';
import { ASSET_ID_FIELD_ID, IMPACT_FIELD_ID, RICH_TEXT_FIELD_IDS } from 'features/srPanel/consts';
import { showPrompt } from 'store/messagePromptSlice';
import {
  editFloatingTicketPanel,
  closeFloatingTicketPanel,
  selectAutoPopulateSectionDisplay,
  toggleAutoPopulateSection,
} from 'store/floatingTicketPanelSlice';
import usePerformanceMeasure from 'common/utils/hooks/usePerformanceMeasure';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import LoadingOverlayMessage from 'features/queue/grid/loadingOverlayMessage';
import { setToasterMessage } from 'store/globalSlice';
import { useQueryClient } from '@tanstack/react-query';
import { APP_CONSTANTS, TICKET_EVENTS, TICKETS_OPEN_URL } from 'constants/index';
import { TRACK_EVENTS } from 'constants/trackEvents';
import useAnalytics from 'common/utils/hooks/useAnalytics';
import { CONSTANTS as AUTO_POPULATE_CONSTANTS } from 'common/components/autoPopulateTicket/constants';
import AttachmentDropzone from 'common/components/attachmentDropzone';
import { editorActionTypes } from 'features/resolutionPanel/middlePanel/auditLog/constants';
import { useEditorActions } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { FIELD_TYPE } from 'common/components/grid/constants';
import { SR_CODE_TYPE_MAP, SR_TYPES } from '../TicketPanel/constants';
import { FloatingTicketInstanceModes } from './floatingTicketPanelManager/modes';
import { parseEmptyCategories } from './utils/utils';
import FloatingActions from './actions/floatingActions';
import FloatingHeader from './floatingHeader';
import {
  StyledFloatingHeaderWrapper,
  StyledFloatingTicketWrapper,
  StyledLoader,
  StyledLoaderFooter,
  StyledLoaderHeader,
  useStyles
} from './style';
import { CONSTANTS } from './utils/constants';
import useTexts from './useTexts';
import useAutoPopulateTexts from '../../common/components/autoPopulateTicket/useTexts';

const { NEW_VAL } = CONSTANTS;

const ticketCreatedMessage = (text, ticketId) => text.replace('__id__', ticketId);
const DEFAULT_REPORT_TEMPLATE_ID = -1;
let reportedTemplateId = DEFAULT_REPORT_TEMPLATE_ID;

export default function FloatingTicketPanel({ panel, panelWidth, ...rest }) {
  const isFieldsValid = useSelector(selectIsNewSrFieldsValid);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const srId = NEW_VAL;
  const isDirtyFormSelectorProperty = `${panel.id}-${srId}`;
  const {
    messageCreatedText,
    viewSR,
    copyURL,
    failedToCreateTicket,
    areYouSureBody,
    areYouSureTitle,
    areYouSureCancel,
    areYouSureOk,
    discardChangesPrompt,
    discardChangesPromptOkBtn,
    discardChangesPromptCancelBtn,
  } = useTexts();
  const { descriptionAsNoteTitle } = useAutoPopulateTexts();
  const [isSaving, setIsSaving] = useState(false);
  const { data: isTripleCategory } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const { mutateAsync: saveSR } = useSaveSR();
  const { mutateAsync: reassignTempAttachments } = useReassignTempAttachmentsMutation();
  const { createSrAttachment } = useTicketAttachments();
  const [drivenTemplate, setCategoryDrivenTemplate] = useState();
  const [selectedSrType, changeSrType] = useState(panel.srType || SR_TYPES.incident);
  const { sr, template } = useCreateNewSR(NEW_VAL, selectedSrType);
  const [srObject, setSrObject] = useState(sr?.data);
  const [autoPopulateData, setAutoPopulateData] = useState('');
  const [srFieldsToUpdate, setSrFieldsToUpdate] = useState();
  const [currentTemplateId, setCurrentTemplateId] = useState(NEW_VAL);
  const isNavBarCollapsed = useSelector(selectIsNavBarCollapsed);
  const [srPanelCollapsed, setSrPanelCollapsed] = useState(false);
  const isCategoryDrivenEnabled = useCategoryDrivenEnabled()?.data?.data;
  const formState = useSelector(selectFormData);
  const forceFloatingTicketShowError = useSelector(selectForceFloatingTicketShowValidationError);
  const isAutoPopulateEnabled = useAutoPopulationEnabled();
  const [isAutoPopulateSaving, setIsAutoPopulateSaving] = useState(false);
  const [isSkippedAutoPopulate, setIsSkippedAutoPopulate] = useState(false);
  const { data: sysaidUserData } = useUserInfo(QUERIES_KEYS.USER_DATA);
  const { mutateAsync: getSuggestedCategoriesByText } = useAiTitleAndCategories();
  const trackEvents = useAnalytics();
  const { performanceMeasureEventReport } = usePerformanceMeasure();
  const srObjectFinishedUpdating = useRef();
  const waitForSrObjectToFinishUpdating = useRef();
  const showAutoPopulationSection = useSelector(selectAutoPopulateSectionDisplay);
  const { saveEditorDataByType } = useEditorActions();
  const { mutateAsync: getUserMainAssetAndCi } = useMainAssetAndCi();
  const jiraIssueKeyFn = useRef(null);

  // Remove if everything works properly
  const { data: isConditionEnbaled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.CONDITION_FOR_FLOATING_TICKET_PANEL_ENABLED,
    defaultValue: false,
  });

  const { messageWrapper, buttonsWrapper, buttonsDivider, buttonLink } = useStyles();

  const createdTicketMessage = useCallback((srId) => `
    <div class="${messageWrapper}">
      ${messageCreatedText}. 
      <div class="${buttonsWrapper}">
        <a href="${TICKETS_OPEN_URL.SPACES(srId)}" data-testid="viewSR" class="${buttonLink}">
          ${viewSR}
        </a>
        <div class="${buttonsDivider}"></div>
        <button class="${buttonLink}" data-testid="copyLink">
          ${copyURL}
        </button>
      </div>
    </div>
  `, [messageWrapper, buttonsWrapper, buttonsDivider, buttonLink, messageCreatedText, viewSR, copyURL]);

  const handleSetSrObject = (newSr) => {
    srObjectFinishedUpdating.current = false;
    setSrObject(newSr);
  };

  const handleChangeSrType = (type) => {
    switch (type) {
      case SR_TYPES.incident:
        trackEvents(TRACK_EVENTS.CHANGE_SR_TYPE_TO_INCIDENT_FROM_NEW_TICKET);
        break;
      case SR_TYPES.change:
        trackEvents(TRACK_EVENTS.CHANGE_SR_TYPE_TO_CHANGE_FROM_NEW_TICKET);
        break;
      case SR_TYPES.request:
        trackEvents(TRACK_EVENTS.CHANGE_SR_TYPE_TO_REQUEST_FROM_NEW_TICKET);
        break;
      case SR_TYPES.problem:
        trackEvents(TRACK_EVENTS.CHANGE_SR_TYPE_TO_PROBLEM_FROM_NEW_TICKET);
        break;
      default:
        break;
    }
    setCurrentTemplateId(NEW_VAL);
    setCategoryDrivenTemplate(null);
    changeSrType(type);
  };

  useEffect(() => {
    handleSetSrObject(sr?.data);
  }, [sr?.data]);

  useEffect(() => {
    dispatch(setForceFloatingTicketShowError(false));
  }, [dispatch]);

  const objectUpdates = useRef(-1);

  const { data: updatedSrWithAssociatedFields } = useUpdateAssociatedFields({ srObject, ...srFieldsToUpdate });

  const [srAttachmentsList, setSrAttachmentsList] = useState([]);

  useEffect(() => {
    if (updatedSrWithAssociatedFields) {
      setSrFieldsToUpdate();
      if (updatedSrWithAssociatedFields.richTextFields) {
        let descriptionValue = updatedSrWithAssociatedFields.richTextFields;
        try {
          descriptionValue = JSON.parse(getRichTextValueByColumnName(descriptionValue, 'description').jsonContent).text;
        } catch {
          descriptionValue = updatedSrWithAssociatedFields.richTextFields;
        }
        setSrObject((prev) => ({ ...prev, ...updatedSrWithAssociatedFields, description: descriptionValue }));
      } else {
        setSrObject((prev) => ({ ...prev, ...updatedSrWithAssociatedFields }));
      }
    }
  }, [updatedSrWithAssociatedFields]);

  let templateObject;
  if (template) {
    templateObject = template.data;
  }
  const hasEditPermissions = true;

  if (currentTemplateId === NEW_VAL && templateObject?.header?.length > 0) {
    setCurrentTemplateId(templateObject.id);
  }
  const loadingNonDefaultTemplate =
    currentTemplateId !== NEW_VAL && templateObject?.id && currentTemplateId !== templateObject?.id;
  const templateData = useGetTemplateById(loadingNonDefaultTemplate ? currentTemplateId : undefined, 'ticket');
  let currentTemplate = loadingNonDefaultTemplate ? templateData?.data : templateObject;
  if (drivenTemplate) {
    currentTemplate = drivenTemplate;
  }

  const fields = useMemo(
    () => currentTemplate?.sections?.flatMap((section) => section?.sectionRows?.flatMap((row) => row.fields)) || [],
    [currentTemplate],
  );
  const headerFields = currentTemplate?.header || [];

  const fieldsMap = useMemo(() => getFieldsMap(fields), [fields]);

  const updateSrObject = useCallback((updateObj, key) => {
    trackEvents(TRACK_EVENTS.CREATE_NEW_SR_CREATION)
    objectUpdates.current++;
    srObjectFinishedUpdating.current = false;
    setSrFieldsToUpdate({ updateObj, key });
  }, [trackEvents]);

  const fillDefaultValues = useCallback(() => {
    const newSr = {};
    newSr.ticketTemplateId = currentTemplate?.id;
    newSr.type = selectedSrType;
    newSr.srType = SR_CODE_TYPE_MAP[selectedSrType];
    const manuallyChangedFields = formState[isDirtyFormSelectorProperty];
    const fieldSrList = {};

    fields?.forEach((field) => {
      const { fieldName, fieldId, defaultValue, required } = field;
      fieldSrList[fieldName] = {
        required,
        isValid: !required,
      };
      const isRichTextField = RICH_TEXT_FIELD_IDS.includes(fieldId);
      const manuallyChangeValue = manuallyChangedFields?.[fieldName]?.value;
      let valueToChange;
      if (manuallyChangeValue) {
        const isIdValueField = [IMPACT_FIELD_ID, ASSET_ID_FIELD_ID].includes(fieldId);
        valueToChange = typeof manuallyChangeValue === 'object' ? manuallyChangeValue?.value : manuallyChangeValue;
        if (isIdValueField) {
          valueToChange = manuallyChangeValue?.id;
        }
        if (isRichTextField) {
          valueToChange = JSON.stringify({ text: manuallyChangeValue });
        }
        fieldSrList[fieldName].isValid = true;
      } else if (fieldValueExists({ value: defaultValue, fieldId, fieldName })) {
        valueToChange = defaultValue;
        if (isRichTextField) {
          valueToChange = getRichTextValueByColumnName(defaultValue, fieldName)?.jsonContent;
        }
        fieldSrList[fieldName].isValid = true;
      } else if (fieldName === 'requestUser.agreement') {
        valueToChange = 1;
      }
      if (isRichTextField) {
        let jsonContent = valueToChange || JSON.stringify({ text: '' });
        if (typeof jsonContent === 'object') {
          jsonContent = JSON.stringify(jsonContent);
        }
        newSr.richTextFields = [
          {
            referencedEntityTableName: 'SERVICE_REQ',
            columnName: fieldName,
            jsonContent,
          },
        ];
      }
      if (fieldName === FIELD_TYPE.srType && !valueToChange) {
        valueToChange = newSr[fieldName]
      } 
      newSr[fieldName] = valueToChange;
    });

    currentTemplate?.header.forEach((field) => {
      const { fieldId, fieldName, defaultValue, required } = field;

      const { key, isCategoryKey } = mapToSrKey(fieldName);
      fieldSrList[key] = {
        required,
        isValid: !required,
      };
      if (manuallyChangedFields?.[fieldName]?.value || isCategoryKey) {
        if (isCategoryKey) {
          const { valueToUpdate, isValid } = getCategoryFieldUpdates(
            fieldName,
            manuallyChangedFields,
            srFieldsToUpdate,
          );

          if (valueToUpdate !== null) {
            newSr[key] = valueToUpdate;
            fieldSrList[key].isValid = isValid;
          } else if (fieldValueExists({ value: defaultValue, fieldId, fieldName })) {
            newSr[key] = castCategoryDefaultToNumber(defaultValue);
            fieldSrList[key].isValid = true;
          }
        } else {
          newSr[key] = manuallyChangedFields?.[fieldName]?.value;
          fieldSrList[key].isValid = true;
        }
      } else if (fieldValueExists({ value: defaultValue, fieldId, fieldName })) {
        newSr[key] = field.defaultValue;
        fieldSrList[key].isValid = true;
      }
    });
    dispatch(resetFieldValidationStatus({ fieldSrList: { new: fieldSrList } }));
    handleSetSrObject(newSr);
  }, [
    currentTemplate?.id,
    currentTemplate?.header,
    selectedSrType,
    formState,
    isDirtyFormSelectorProperty,
    fields,
    dispatch,
    srFieldsToUpdate,
  ]);

  const initSrObject = useCallback(() => {
    objectUpdates.current++;
    // dispatch(removeRichFieldBySource({ sourceId }));
    fillDefaultValues();
  }, [fillDefaultValues]);

  const handleDirtyFields = useCallback(
    (props) => {
      const fieldName = Object.keys(props)[0];
      const value = props[fieldName];

      dispatch(updateIsDirtyStatus({ srId: isDirtyFormSelectorProperty, fieldName, value }));
    },
    [dispatch, isDirtyFormSelectorProperty],
  );

  const handleTemplateChanged = useCallback((template) => {
    setCategoryDrivenTemplate(null);
    setCurrentTemplateId(template.id);
  }, []);

  const scrollToInvalidField = useCallback(() => {
    setTimeout(() => {
      const invalidField = document.querySelector('.with-error');
      if (invalidField) {
        dispatch(setIsSectionsExpanded(true));
        invalidField.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    }, 150);
  }, [dispatch]);

  const maybeClosePanel = useCallback(
    ({ isCancelButtonClicked }) => {
      const isNewTicketNotEmpty = !!Object.values(formState[`${panel.id}-new`] || {}).filter((field) => !!field.value)
        .length;
      if (isCancelButtonClicked && isAutoPopulateEnabled && !isSkippedAutoPopulate) {
        trackEvents(TRACK_EVENTS.POPULATED_SR_CANCEL_CLICK);
      }
      if (isNewTicketNotEmpty) {
        dispatch(
          showPrompt({
            title: areYouSureTitle,
            descriptionText: areYouSureBody,
            btnOkText: areYouSureOk,
            btnCancelText: areYouSureCancel,
            url: panel.id,
            onClickFunctionName: closeFloatingTicketPanel.toString(),
          }),
        );
      } else {
        dispatch(
          editFloatingTicketPanel({
            id: panel.id,
            mode: FloatingTicketInstanceModes.CLOSED,
          }),
        );
      }
    },
    [
      areYouSureBody,
      areYouSureCancel,
      areYouSureOk,
      areYouSureTitle,
      dispatch,
      panel.id,
      isAutoPopulateEnabled,
      trackEvents,
      isSkippedAutoPopulate,
      formState,
    ],
  );

  const attachmentSubResourceType = (fieldName) => {
    if (fieldName === 'description') return { type: SubResourceType.DESCRIPTION, id: 6 };
  };

  const reassignRtfAttachments = useCallback(
    async (richTextField, srId) => {
      const jsonContent = JSON.parse(richTextField.jsonContent);
      const tempAttachmentsToReassign = concat(jsonContent?.inlineImages || [], jsonContent?.attachments || []).map(
        (file) => file.id,
      );
      if (tempAttachmentsToReassign.length) {
        const subResource = attachmentSubResourceType(richTextField.columnName);
        await reassignTempAttachments({
          attachmentIds: tempAttachmentsToReassign,
          param: {
            targetSubResourceId: subResource.id,
            targetSubResourceType: subResource.type,
            resourceId: srId,
            resourceType: ResourceType.SR,
          },
        });
      }
    },
    [reassignTempAttachments],
  );

  const reassignSrAttachments = useCallback(
    async (srId) => {
      const tempAttachmentsToReassign = srAttachmentsList.map((file) => file.id);
      if (tempAttachmentsToReassign.length) {
        await reassignTempAttachments({
          attachmentIds: tempAttachmentsToReassign,
          param: {
            targetSubResourceId: srId,
            targetSubResourceType: SubResourceType.SR,
            resourceId: srId,
            resourceType: ResourceType.SR,
          },
        });
      }
    },
    [reassignTempAttachments, srAttachmentsList],
  );

  const saveAutoPopulateDescriptionAsNote = useCallback(
    async ({ srId, srType }) => {
      saveEditorDataByType({
        updateData: {
          replyTo: 0,
          noteText: `<p><b>${descriptionAsNoteTitle}</b></p><p>${autoPopulateData}</p>`,
          attachments: [],
          inlineImages: [],
          shareWithReqUser: false,
          autoPopulatedSr: true,
        },
        logType: editorActionTypes.NOTE,
        autoPopulatedNewSrId: srId,
        autoPopulatedNewSrType: srType,
      });
    },
    [descriptionAsNoteTitle, autoPopulateData, saveEditorDataByType],
  );

  const srBaseFields = useMemo(() => {
    const fields = ['type', 'srType', 'ticketTemplateId', 'richTextFields', 'customColumnsFields'];
    return currentTemplate?.header
      ? [...fields, ...currentTemplate?.header.map((field) => mapToSrKey(field.fieldName).key)]
      : fields;
  }, [currentTemplate?.header]);

  const handleGetJiraIssueKey = useCallback((getKeyFn) => {
    jiraIssueKeyFn.current = getKeyFn;
  }, []);

  const fetchJiraIssueKey = useCallback(() => {
    if (jiraIssueKeyFn.current) {
      return jiraIssueKeyFn.current();
    }
    return null;
  }, []);

  const handleSaveSR = useCallback(async () => {
    if (!isFieldsValid) {
      dispatch(setForceFloatingTicketShowError(true));
      scrollToInvalidField();
      return;
    }
    if (!srObjectFinishedUpdating.current) {
      waitForSrObjectToFinishUpdating.current = true;
      return;
    }

    let updatedSrObject = { ...srObject };
    if (!isNil(srObject.sr_cust_addon_jira_action)) {
      const jiraIssueKey = await fetchJiraIssueKey();
      if (isEmpty(jiraIssueKey)) {
        return;
      }
      updatedSrObject = { ...srObject, sr_cust_addon_jira_issuekey: jiraIssueKey };
    }

    const startSavingTime = performance.now();
    setIsSaving(true);

    let autoPopulatedTicket = false;
    if (isAutoPopulateEnabled && !isSkippedAutoPopulate && autoPopulateData) {
      trackEvents(TRACK_EVENTS.POPULATED_SR_CREATE_CLICK);
      autoPopulatedTicket = true;
    }

    delete updatedSrObject.description;
    const customColumnsFields = {};
    Object.keys(updatedSrObject).forEach((srKey) => {
      const field = fieldsMap[srKey];
      if (!field && !srBaseFields.includes(srKey)) {
        // we're removing the fields that doesn't belong to the current template from the sr before save, but we are keeping them in the sr object for consistency of values
        delete updatedSrObject[srKey];
      }
      if (field?.customColumn) {
        customColumnsFields[srKey] = updatedSrObject[srKey] || null;
        delete updatedSrObject[srKey];
      }
    });
    if (!isEmpty(customColumnsFields)) {
      updatedSrObject.customColumnsFields = customColumnsFields;
    }

    // TODO: Remove this function, utils and utils.test files - when BE will support to save categories with undefined values.
    updatedSrObject = parseEmptyCategories(srId, isTripleCategory, updatedSrObject);

    const newAuditLogRes = await saveSR(
      { ...updatedSrObject, autoPopulatedTicket },
      {
        onSuccess: async ({ sr }) => {
          if (!sr) {
            setIsSaving(false);
            if (failedToCreateTicket) {
              dispatch(
                setToasterMessage({
                  type: 'error',
                  message: failedToCreateTicket,
                }),
              );
            }
            return;
          }
          if (updatedSrObject?.richTextFields?.length) {
            const promises = [];
            for (const richTextField of updatedSrObject.richTextFields) {
              promises.push(reassignRtfAttachments(richTextField, sr.id));
            }
            if (srAttachmentsList.length > 0) {
              promises.push(reassignSrAttachments(sr.id));
            }
            if (promises.length) await Promise.all(promises);
          }
          setIsSaving(false);
          if (sr) {
            const endSavingTime = performance.now();
            const duration = endSavingTime - startSavingTime;
            performanceMeasureEventReport(PERFORMANCE_MEASURE.EVENTS.CREATED_TICKET_ADDED_TO_QUEUE, {
              tenantId: localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY),
              duration,
            });

            queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.QUEUE_DATA] });
            dispatch(
              editFloatingTicketPanel({
                id: panel.id,
                mode: FloatingTicketInstanceModes.CLOSED,
              }),
            );
            dispatch(
              setToasterMessage({
                message: ticketCreatedMessage(
                  createdTicketMessage(sr.id),
                  sr.id,
                ),
                onClickFunctionName: TICKET_EVENTS.TICKET_CREATED,
                id: sr.id,
                showFor: 10000,
              }),
            );
            // Auto populate tickets - if description field not exists in the SR -> add the auto populate description as a journey note
            const srHasDescriptionField = Object.keys(fieldsMap)?.some(
              (field) => field === AUTO_POPULATE_CONSTANTS.DESCRIPTION,
            );
            if (isAutoPopulateEnabled && !isSkippedAutoPopulate && autoPopulateData && !srHasDescriptionField) {
              saveAutoPopulateDescriptionAsNote({ srId: sr.id, srType: sr.srType });
            }
          }
        },
        onError: (error) => {
          setIsSaving(false);
          console.log(error);
          if (failedToCreateTicket) {
            dispatch(
              setToasterMessage({
                type: 'error',
                message: failedToCreateTicket,
              }),
            );
          }
        },
      },
    );

    return newAuditLogRes;
  }, [
    srId,
    isFieldsValid,
    isTripleCategory,
    srObject,
    fieldsMap,
    srBaseFields,
    saveSR,
    dispatch,
    scrollToInvalidField,
    reassignRtfAttachments,
    reassignSrAttachments,
    performanceMeasureEventReport,
    failedToCreateTicket,
    panel.id,
    queryClient,
    isAutoPopulateEnabled,
    trackEvents,
    autoPopulateData,
    saveAutoPopulateDescriptionAsNote,
    isSkippedAutoPopulate,
    srAttachmentsList.length,
    createdTicketMessage,
    fetchJiraIssueKey,
  ]);

  const prevSrObject = usePreviousValue(srObject);

  useEffect(() => {
    if (srObject && prevSrObject !== srObject) {
      srObjectFinishedUpdating.current = true;
      if (waitForSrObjectToFinishUpdating.current) {
        waitForSrObjectToFinishUpdating.current = false;
        handleSaveSR();
      }
    }
  }, [prevSrObject, srObject, handleSaveSR]);

  useEffect(
    () => () => {
      reportedTemplateId = DEFAULT_REPORT_TEMPLATE_ID;
      performance.clearMarks(PERFORMANCE_MEASURE.FLOATING_TICKET_PANEL.POPULATE_FIELDS);
      performance.clearMarks(PERFORMANCE_MEASURE.EVENTS.POPULATE_NEW_TICKET_FIELDS);
      performance.clearMarks(PERFORMANCE_MEASURE.QUEUE.CREATE_TICKET_BTN);
    },
    [],
  );

  const prevCurrentTemplate = usePreviousValue(currentTemplate);

  useEffect(
    () => () => {
      dispatch(resetFieldValidationStatus({ resetNew: true }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (prevCurrentTemplate?.id !== currentTemplate?.id && currentTemplate && templateObject) {
      initSrObject();
    }
  }, [prevCurrentTemplate?.id, currentTemplate?.id, initSrObject, currentTemplate, templateObject]);

  useEffect(() => {
    if (panel?.mode === FloatingTicketInstanceModes.AUTO_POPULATE) {
      dispatch(toggleAutoPopulateSection(true));
    }
  }, [dispatch, panel?.mode]);

  const categoryDrivenTemplate = useCategoryDrivenTemplate(selectedSrType);
  if (categoryDrivenTemplate.data?.header?.length > 0 && categoryDrivenTemplate.data?.id !== currentTemplateId) {
    const templateData = categoryDrivenTemplate?.data;
    handleTemplateChanged(templateData);
    setCategoryDrivenTemplate(templateData);
  }

  const toggleSrPanelCollapse = () => {
    setSrPanelCollapsed(!srPanelCollapsed);
  };

  if (panel?.mode === FloatingTicketInstanceModes.REGULAR_MODE_OPENED) {
    performance.mark(PERFORMANCE_MEASURE.FLOATING_TICKET_PANEL.POPULATE_FIELDS);
  }

  useEffect(() => {
    if (showAutoPopulationSection) return;
    performanceMeasureEventReport(
      PERFORMANCE_MEASURE.EVENTS.POPULATE_NEW_TICKET_FIELDS,
      {
        tenantId: localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY),
        duration:
          performance.getEntriesByName(PERFORMANCE_MEASURE.FLOATING_TICKET_PANEL.POPULATE_FIELDS)[0]?.startTime -
          performance.getEntriesByName(PERFORMANCE_MEASURE.QUEUE.CREATE_TICKET_BTN)[0]?.startTime,
        floatingPanelMode: autoPopulateData
          ? FloatingTicketInstanceModes.AUTO_POPULATE
          : FloatingTicketInstanceModes.REGULAR_MODE_OPENED,
      },
      //eslint-disable-next-line eqeqeq
      srObject?.ticketTemplateId == null || srObject?.ticketTemplateId == reportedTemplateId,
    );

    reportedTemplateId = srObject?.ticketTemplateId;
  }, [performanceMeasureEventReport, srObject?.ticketTemplateId, autoPopulateData, showAutoPopulationSection]);

  useLinkedSrLists({
    sr: srObject,
    fields: [...headerFields, ...fields],
    dependantFields: currentTemplate?.dependantFields,
    updateSrFunc: updateSrObject,
  });

  if (!srObject.ticketTemplateId && isConditionEnbaled) {
    return null;
  }

  const handleChangeAutoPopulateData = debounce((e) => {
    setAutoPopulateData(e?.target?.value);
  }, 300);

  const closeAutoPopulateSection = () => {
    dispatch(toggleAutoPopulateSection(false));
    dispatch(
      editFloatingTicketPanel({
        ...panel,
        mode:
          panel.mode === FloatingTicketInstanceModes.AUTO_POPULATE
            ? FloatingTicketInstanceModes.REGULAR_MODE_OPENED
            : FloatingTicketInstanceModes.EXPANDED,
      }),
    );
  };

  const handleCloseAutoPopulateSection = () => {
    if (autoPopulateData) {
      dispatch(
        showPrompt({
          title: areYouSureTitle,
          descriptionText: discardChangesPrompt,
          btnOkText: discardChangesPromptOkBtn,
          btnCancelText: discardChangesPromptCancelBtn,
          floatingPanelMode: panel.mode,
          floatingPanelId: panel.id,
          onClickFunctionName: toggleAutoPopulateSection.toString(),
          okCb: () => {
            trackEvents(TRACK_EVENTS.WARNING_POPUP_ABORT_MISSION_CLICK);
            setIsSkippedAutoPopulate(true);
          },
          cancelCb: () => {
            trackEvents(TRACK_EVENTS.WARNING_POPUP_AUTO_FILL_FOR_ME_CLICK);
          },
        }),
      );
    } else {
      closeAutoPopulateSection();
      setIsSkippedAutoPopulate(true);
      trackEvents(TRACK_EVENTS.NEW_SR_I_PREFER_MANUAL_LABOR_CLICK);
    }
  };

  const handleTitleAndCategoriesPopulation = async ({ data, updatedSrObject }) => {
    const { data: aiData } = await getSuggestedCategoriesByText(data);
    if (aiData) {
      const { title, category } = aiData;
      if (!updatedSrObject?.title) {
        updatedSrObject.title = title;
      }
      const hasDefaultCategory = !!(
        srObject?.primaryCategory ||
        srObject?.secondaryCategory ||
        srObject?.thirdLevelCategory
      );
      if (!isCategoryDrivenEnabled && !hasDefaultCategory) {
        updatedSrObject.primaryCategory = category?.[AUTO_POPULATE_CONSTANTS.FIRST_LEVEL_KEY];
        updatedSrObject.secondaryCategory = category?.[AUTO_POPULATE_CONSTANTS.SECOND_LEVEL_KEY];
        updatedSrObject.thirdLevelCategory = category?.[AUTO_POPULATE_CONSTANTS.THIRD_LEVEL_KEY];
      }
    }
  };

  const handleRequestUserFieldsPopulation = ({ field, dataField, commonFields }) => {
    if (field.fieldName.replace(AUTO_POPULATE_CONSTANTS.REQUEST_USER_PREFIX, '') === dataField) {
      commonFields.push({ [field.fieldName]: sysaidUserData[dataField] });
    }
  };

  const handleCommonFieldsPopulation = ({ updatedSrObject }) => {
    const userDataFields = Object.keys(sysaidUserData);
    const commonFields = [];
    userDataFields.forEach((dataField) => {
      fields.forEach((field) => {
        const srFieldValue = field.defaultValue || getFieldValue(field, srObject);
        if (!isEmpty(srFieldValue)) return;
        if (field.fieldName.includes(AUTO_POPULATE_CONSTANTS.REQUEST_USER_PREFIX)) {
          handleRequestUserFieldsPopulation({ field, dataField, commonFields });
        } else if (field.fieldName === dataField) {
          commonFields.push({ [field.fieldName]: sysaidUserData[dataField] });
        }
      });
    });
    commonFields?.forEach((field) => {
      const key = Object.keys(field)?.[0];
      const value = field[key];
      updatedSrObject[key] = value;
    });
  };

  const handleDescriptionPopulation = ({ updatedSrObject }) => {
    const newRichTextFields = updatedSrObject.richTextFields || [];
    const descriptionIndex = newRichTextFields?.findIndex(
      (field) => field.columnName === AUTO_POPULATE_CONSTANTS.DESCRIPTION,
    );
    if (descriptionIndex !== -1) {
      newRichTextFields[descriptionIndex].jsonContent = JSON.stringify({ text: autoPopulateData, attachments: [] });
      updatedSrObject.richTextFields = newRichTextFields;
    } else {
      const richTextFieldsCount = newRichTextFields?.length;
      newRichTextFields[richTextFieldsCount] = {};
      newRichTextFields[richTextFieldsCount].referencedEntityTableName = 'SERVICE_REQ';
      newRichTextFields[richTextFieldsCount].columnName = AUTO_POPULATE_CONSTANTS.DESCRIPTION;
      newRichTextFields[richTextFieldsCount].jsonContent = JSON.stringify({ text: autoPopulateData, attachments: [] });
      updatedSrObject.richTextFields = newRichTextFields;
    }
  };

  const handleAssetAndCi = async ({ updatedSrObject }) => {
    const { asset, ci } = (await getUserMainAssetAndCi()) || {};
    if (asset && !updatedSrObject?.assetId) {
      updatedSrObject.assetId = asset.id;
    }
    if (ci && !updatedSrObject?.ciId) {
      updatedSrObject.ciId = ci.id;
    }
  };

  const handleFieldsDataPopulation = async (data) => {
    const updatedSrObject = { ...srObject };
    await handleTitleAndCategoriesPopulation({ data, updatedSrObject });
    await handleAssetAndCi({ updatedSrObject });
    handleCommonFieldsPopulation({ updatedSrObject });
    handleDescriptionPopulation({ updatedSrObject });
    setSrObject(updatedSrObject);
  };

  const handleSaveAutoPopulation = async () => {
    trackEvents(TRACK_EVENTS.NEW_SR_AUTO_FILL_FOR_ME_CLICK);
    setIsAutoPopulateSaving(true);
    await handleFieldsDataPopulation({ text: autoPopulateData, srType: parseInt(srObject.srType, 10) });
    closeAutoPopulateSection();
    setIsAutoPopulateSaving(false);
  };

  const isLoading =
    !currentTemplate ||
    (templateData.status !== 'success' && loadingNonDefaultTemplate) ||
    template.status !== 'success' ||
    isAutoPopulateSaving;
  if (isLoading) {
    return (
      <StyledFloatingTicketWrapper {...rest} data-testid="new-ticket-panel">
        <div className="loader" data-testid="new-ticket-panel-loader">
          {!isAutoPopulateSaving && <StyledLoaderHeader />}
          <StyledLoader isAutoPopulateSaving={isAutoPopulateSaving}>
            <LoadingOverlayMessage className="floating-ticket" />
          </StyledLoader>
          {!isAutoPopulateSaving && <StyledLoaderFooter />}
        </div>
      </StyledFloatingTicketWrapper>
    );
  }

  const handleUploadAttachments = (files) => {
    Array.from(files).forEach(async (file) => {
      const { metadata } = await createSrAttachment({
        file,
        subResourceType: SubResourceType.TEMP,
        resourceType: ResourceType.SR,
        resourceId: 0,
        visibility: true,
        inline: false,
      });
      metadata.src = URL.createObjectURL(file);
      setSrAttachmentsList((prev) => [...prev, metadata]);
    });
  };
  const handleDeleteAttachment = (attachmentId) => {
    setSrAttachmentsList((prev) => prev.filter((attachment) => attachment.id !== attachmentId));
  };

  const handleCancelClicked = () => {
    trackEvents(TRACK_EVENTS.CANCEL_NEW_SR_CREATION)
    maybeClosePanel({ isCancelButtonClicked: true })
  }

  return (
    <AttachmentDropzone dropzoneDisabled={showAutoPopulationSection} onAttachmentDrop={handleUploadAttachments}>
      <StyledFloatingTicketWrapper {...rest} data-testid="new-ticket-panel">
        <StyledFloatingHeaderWrapper isAutoPopulateDisplayed={showAutoPopulationSection}>
          <FloatingHeader
            isCategoryDrivenTemplatesEnabled={isCategoryDrivenEnabled}
            ticketTitle={srObject?.title}
            panel={panel}
            srType={selectedSrType}
            onTypeChange={handleChangeSrType}
            onTemplateChanged={handleTemplateChanged}
            currentTemplate={currentTemplate}
            onCloseClicked={maybeClosePanel}
            panelWidth={panelWidth}
            showAutoPopulationSection={showAutoPopulationSection}
          />
          {showAutoPopulationSection && (
            <AutoPopulateTicket
              autoFocus
              data={autoPopulateData}
              handleChange={handleChangeAutoPopulateData}
              handleCancel={handleCloseAutoPopulateSection}
              handleSave={handleSaveAutoPopulation}
              panelMode={panel.mode}
              srHasDescription={Object.keys(fieldsMap)?.some((field) => field === AUTO_POPULATE_CONSTANTS.DESCRIPTION)}
            />
          )}
          {!showAutoPopulationSection && (
            <Header
              isHorizontalView={false}
              srObject={srObject}
              handleSaveSR={updateSrObject}
              template={currentTemplate}
              isNewSR
              handleDirty={handleDirtyFields}
              isFloatingTicketPanel
              isValidTicket="true"
              hasEditPermissions={hasEditPermissions}
              className="floating-ticket"
              forceStopuseStateStore
              forceSrId={srId}
              onTemplateChanged={handleTemplateChanged}
              isDisabled={showAutoPopulationSection}
              autoFocus={!showAutoPopulationSection}
            />
          )}
        </StyledFloatingHeaderWrapper>
        <div className="floatingPanelSrPanel">
          <SrPanel
            sr={srObject}
            isNavBarCollapsed={isNavBarCollapsed}
            srPanelCollapsed={srPanelCollapsed}
            toggleSrPanelCollapse={toggleSrPanelCollapse}
            handleSaveSR={updateSrObject}
            template={currentTemplate}
            isFloatingTicketPanel
            hasEditPermissions={hasEditPermissions}
            handleDirtyFields={handleDirtyFields}
            srAttachmentsList={srAttachmentsList}
            handleDeleteAttachment={handleDeleteAttachment}
            onGetJiraIssueKey={handleGetJiraIssueKey}
          />
        </div>
        {!showAutoPopulationSection && (
          <FloatingActions
            isNotValid={!isFieldsValid && forceFloatingTicketShowError}
            isSaving={isSaving}
            isDisabled={showAutoPopulationSection}
            onSaveClicked={handleSaveSR}
            onCancelClicked={handleCancelClicked}
            onUploadAttachmentClicked={handleUploadAttachments}
          />
        )}
      </StyledFloatingTicketWrapper>
    </AttachmentDropzone>
  );
}
